@import '/theme.css';

.component,
.componentWithAdditionalLabelOnTheRight {
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;

  & > .legend {
    margin-bottom: sizeX3s;
  }
}

.labels {
  display: flex;
  justify-content: space-between;
}

.legend {
  display: block;
  cursor: pointer;
  font-size: fontSizeS;
  line-height: 1.1;

  &.disabled {
    color: cGray;
    cursor: default;
  }

  &.required::after {
    content: ' *';
    color: cBlue;
  }
}

.formItems {
  & > :not(:last-child) {
    margin-bottom: sizeX3s;
  }

  &.streetAndHouseNumber {
    display: flex;

    & > :not(:last-child) {
      margin-bottom: 0;
    }

    & > :first-child {
      width: 60%;
    }

    & > :last-child {
      width: 40%;
    }

    & > :not(:first-child) {
      margin-left: sizeX3s;
    }
  }

  &.postalCodeAndCity {
    display: flex;

    & > :not(:last-child) {
      margin-bottom: 0;
    }

    & > :first-child {
      width: 40%;
    }

    & > :last-child {
      width: 60%;
    }

    & > :not(:first-child) {
      margin-left: sizeX3s;
    }
  }
}
