@import '/theme.css';

.component {
  padding: sizeSm;
}

.componentWithXsPadding {
  padding: sizeX4s;
}

.componentBase {
  background-color: cBackground;
  border-radius: borderRadius;
}
