@import '/theme.css';

@value maxButtonWidth: 320px;

.componentBase {
  & > .grid {
    margin: calc(sizeX3s / -2) calc(sizeXs / -2);
  }
}

.grid {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  & > * {
    max-width: maxButtonWidth;
    margin: calc(sizeX3s / 2) calc(sizeXs / 2);
  }

  &.vertical {
    flex-direction: column;
  }

  &.right {
    justify-content: flex-end;
  }
}
