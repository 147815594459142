@import '/theme.css';
@import '/utilities.css';

.component {
  padding: 0;
}

.input {
  @apply --visuallyHidden;
}

.flag {
  width: sizeSm !important;
  display: block;
  opacity: 0.2;

  &.isActive {
    opacity: 1;
    box-shadow: inset 0 0 0 2px transparent;
    background-color: transparent;
  }
}
